<template>
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="mb-4 text-center">
              <router-link :to="{name: 'Home'}" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" class="mb-4 avatar-xxl rounded bg-white object-fit-cover" alt="">
              </router-link>
              <h1 class="mb-1 fw-bold">Xác thực mã</h1>
              <span>Bạn đã có tài khoản?&nbsp;
                 <router-link :to="{name: 'Login'}" class="ms-1">Đăng nhập</router-link>
              </span>
            </div>
            <form v-on:submit.prevent="validateCode" autocomplete="off">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="code">Mã xác thực:</label>
                    <input type="text" v-model="code" id="code" class="form-control" name="code" placeholder="Mã code"
                           required />
                  </div>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" :disabled="disabled">
                    Xác thực
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="validateCodeModal" title="Xác thực code" :type="messageModalType" :message="messageModalMessage" />
</template>

<script>
import ApiService from "@/core/services/api.service";
import Modal from "@/components/Modal";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'ValidateCode',
  props: {
    school: Object
  },
  components: {
    Modal
  },
  data() {
    return {
      code: "",
      identity: "",
      type: "",
      messageModalType: "",
      messageModalMessage: "",
      disabled: false
    }
  },
  methods: {
    validateCode() {
      this.disabled = true;
      this.messageModalTitle = "Đổi mật khẩu";

      let query = `mutation($identity: String!, $code: String!, $type: String!) {
        validateCode(identity: $identity, code: $code, type: $type)
      }`;

      ApiService.graphql(query, {identity: this.identity, code: this.code, type: this.type})
          .then(({data}) => {
            if (data.data && data.data.validateCode) {
              this.messageModalMessage = "Xin chúc mừng, bạn đã xác thực thành công";
              this.messageModalType = "success";
              openModal("validateCodeModal");
              this.$router.push({name: "Login"});
            } else {
              this.disabled = false;
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("validateCodeModal");
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("validateCodeModal");
          });
    }
  },
  created() {
    this.code = this.$route.query.code || "";
    this.type = this.$route.query.type || "";
    this.identity = this.$route.query.identity || "";

    if (this.code) {
      this.validateCode();
    }
  }
}
</script>
